import React from "react"

import HeaderWrapper from "./header_wrapper"
import NavOfferings from "./nav_offerings"

export default function HeaderOfferings({ heroTitle = null, children }) {
  const heroImage = "/images/slider/Services--Banner.jpg"
  const heroMobileImage = "/images/slider/mobile/Services--Banner.jpg"
  const breadcrumbSecondaryItem = "Offerings"
  const breadcrumbSecondaryItemUrl = "/services/offerings"

  return (
    <HeaderWrapper
      heroTitle={heroTitle + " | Offerings"}
      heroImage={heroImage}
      heroMobileImage={heroMobileImage}
      breadcrumbSecondaryItem={breadcrumbSecondaryItem}
      breadcrumbSecondaryItemUrl={breadcrumbSecondaryItemUrl}
      NavComponent={NavOfferings}
    >
      {children}
    </HeaderWrapper>
  )
}
