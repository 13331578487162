import React from "react"

import HeaderOfferings from "../../../components/header_offerings"

export default function ComprehensiveWealthManagement() {
  const heroTitle = "Comprehensive Wealth Management"

  return (
    <HeaderOfferings heroTitle={heroTitle}>
      <h2 class="h2 h2-sm">{heroTitle}</h2>
      <p>
        Offer holistic and strategic financial advice to lay the foundations for all your future
        financial needs. We design our solutions based on a complete understanding of your personal
        circumstances in order to help you achieve your short term and long term financial goals and
        objectives.
      </p>

      <p>Our specialist areas of advice include:</p>

      <ul class="list-icon list-icon-check list-icon-colored mt-3">
        <li>
          designing and setting up comprehensive financial plans to maximise and enhance wealth
        </li>
        <li>
          strategic and tactical asset allocation planning. Complementary investment strategies are
          combined with the asset allocation framework to produce a favorable risk and return
          profile
        </li>
        <li>disciplined approach to executing on the long term investment plan</li>
      </ul>
    </HeaderOfferings>
  )
}
