import React from "react"
import { Link } from "gatsby"

export default function NavOfferings() {
  return (
    <>
      <Link to="/services/offerings" className="back--home">
        <i class="fas fa-chevron-left"></i> <span>Back to Offerings</span>
      </Link>

      <ul class="sub--pages sub--pages-sm">
        <li>
          <Link
            to="/services/offerings/comprehensive-wealth-management"
            activeClassName="is--active"
          >
            Comprehensive Wealth Management
          </Link>
        </li>
        <li>
          <Link to="/services/offerings/direct-equities-advisory" activeClassName="is--active">
            Direct Equities Advisory
          </Link>
        </li>
        <li>
          <Link to="/services/offerings/mutual-funds-advisory" activeClassName="is--active">
            Mutual Funds Advisory
          </Link>
        </li>
        <li>
          <Link to="/services/offerings/second-look" activeClassName="is--active">
            Second Look
          </Link>
        </li>
      </ul>
    </>
  )
}
